import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import { BASE_PATHS, safeGet, safeGetForLanguage, serialize } from 'utils';
import {
  EMBEDDED_ENTRY_TYPES,
  CURRENT_LANGUAGE,
  CONTENTFUL_ENV_VARIABLES,
  RECIRC_LIST_FORMAT,
} from 'utils/constants';
import {
  BigList,
  Bookmark,
  ButtonLink,
  ContactForm,
  CTA,
  CustomRichText,
  ColumnCTA,
  ExpandCollapse,
  InlineIcon,
  InlineFontawesomeIcon,
  IconCTA,
  Grid,
  OpenLinkList,
  Spacer,
  Link,
  MediaLayout,
  ReadMoreLess,
  RecircList,
  RecircSingle,
  RichTextCarousel as Carousel,
  Tabs,
  SidebarImageCTA,
  PullQuote,
  StickyNavigation,
  SidebarStickyMenu,
  Timeline,
  CustomArticleColumnCTA,
  OutlinedText,
  TwoUpList,
  VideoPlayer,
  Ceros,
  LinkText,
  HeroBanner,
  VideoCarousel,
} from 'components';
import { isString } from './string';

import get from 'lodash.get';

export const renderEntryHyperlink = render => ({ data: { target } }, children) => {
  const contentType = get(target, ['sys', 'contentType', 'sys', 'id']);
  const slug = get(target, ['fields', 'slug', 'en-US']);
  let path = '';
  switch (contentType) {
    case 'homePage':
      path = BASE_PATHS['home'];
      break;
    case 'article':
    case 'topic':
      path = `/${BASE_PATHS['insights']}/${slug}`;
      break;
    case 'insightsLanding':
      path = `/${BASE_PATHS['insights']}`;
      break;
    case 'advisorProfilePage':
      path = `/${BASE_PATHS['advisors']}/${slug}`;
      break;
    case 'contact':
    default:
      path = `/${slug}`;
  }

  return render(path, children);
};

export const renderHyperlink = render => ({ data: { uri } }, children) => render(uri, children);

export const renderOutlinedText = ({ content }) => {
  const getJoinedValues = content =>
    content.reduce(
      (acc, { value, content }) => `${acc}${isString(value) ? value : getJoinedValues(content)}`,
      '',
    );

  return <OutlinedText content={getJoinedValues(content)} />;
};

export const renderAsset = render => ({ data: { target }, content }) => {
  const { value: text } = safeGet(content, [0, 'value']);

  const { title, description, file } = safeGetForLanguage(
    target.fields,
    'title',
    'description',
    'file',
  );
  const { contentType, url } = safeGet(file, 'content', 'url');
  const mimeGroup = contentType ? contentType.split('/')[0] : null;

  return render(mimeGroup, url, title, description, text);
};

export const renderAdditionalFields = render => children => render(children);

/* eslint react/prop-types: 0 */
export const renderEmbeddedEntry = ({ data: { target } }) => {
  const { id: contentType, fields } = safeGet(
    target,
    ['sys', 'contentType', 'sys', 'id'],
    ['fields'],
  );

  const RichTextForm = data => {
    const {
      title,
      displayTitle,
      hubspotFormId,
      qaHubspotFormId,
      format,
      pardotFormLink,
      pardotFormWidth,
      pardotFormHeight,
    } = safeGetForLanguage(
      data,
      ['title'],
      ['displayTitle'],
      ['hubspotFormId'],
      ['qaHubspotFormId'],
      ['format'],
      ['pardotFormLink'],
      ['pardotFormWidth'],
      ['pardotFormHeight'],
    );

    const formId =
      process.env.GATSBY_CONTENTFUL_ENVIRONMENT === CONTENTFUL_ENV_VARIABLES.Production
        ? hubspotFormId
        : qaHubspotFormId || '';

    const { transitionStatus } = useTransitionState();

    return (
      <ContactForm
        title={title}
        hubspotFormId={formId}
        uid={formId}
        displayTitle={displayTitle === true}
        format={format[0]}
        showLoading={transitionStatus !== 'entered'}
        pardotFormLink={pardotFormLink}
        pardotFormWidth={pardotFormWidth}
        pardotFormHeight={pardotFormHeight}
      />
    );
  };

  const RichTextCustom = data => {
    const { content, styling } = data;
    return (
      <CustomRichText content={content && content['en-US']} styling={styling && styling['en-US']} />
    );
  };

  const RichTextBigList = props => {
    const { listItems, type, items } = safeGetForLanguage(
      props,
      ['listItems'],
      ['type'],
      ['items'],
    );

    if (type === 'Two Up List') {
      const mappedItems = items.map(item => ({
        heading: item[0],
        body: item[1],
        link: item[2],
      }));
      return <TwoUpList items={mappedItems} />;
    }

    return <BigList listItems={listItems} type={type} />;
  };

  const RichOpenLinkList = data => {
    const props = serialize(data);
    return <OpenLinkList {...props} />;
  };

  const RichTextGrid = data => {
    const props = serialize(data);
    return <Grid {...props} />;
  };

  const RichTextTimeline = data => {
    const props = serialize(data);
    return <Timeline {...props} />;
  };

  const RichTextCustomArticleColumnCAT = data => {
    const props = serialize(data);
    return <CustomArticleColumnCTA {...props} />;
  };

  const RichTextExpandCollapse = props => {
    const expandCollapseProps = safeGetForLanguage(
      props,
      ['title'],
      ['type'],
      ['collapsed'],
      ['content'],
    );

    return expandCollapseProps.type === 'Read More/Less' ? (
      <ReadMoreLess {...expandCollapseProps} />
    ) : (
      <ExpandCollapse {...expandCollapseProps} />
    );
  };

  const RichTextRecircList = ({ overline, listItems, floatRight }) => {
    const mappedItems = listItems['en-US'].map(item => {
      const article = safeGetForLanguage(
        item,
        ['fields', 'title'],
        ['fields', 'slug'],
        ['fields', 'date'],
      );
      const authorFields = safeGetForLanguage(
        item,
        ['fields', 'author', 'en-US', 'fields', 'name'],
        ['fields', 'author', 'en-US', 'fields', 'image'],
      );
      const authorImageFields = safeGetForLanguage(
        authorFields,
        ['image', 'fields', 'title'],
        ['image', 'fields', 'file'],
      );

      article.author = authorFields;
      article.author.image = authorImageFields;
      return article;
    });

    const format =
      floatRight && floatRight['en-US'] === true
        ? RECIRC_LIST_FORMAT.FLOAT_RIGHT
        : RECIRC_LIST_FORMAT.DEFAULT;

    if (mappedItems.length <= 1) {
      return <RecircSingle article={mappedItems[0]} overline={overline['en-US']} format={format} />;
    } else {
      return <RecircList overline={overline['en-US']} listItems={mappedItems} format={format} />;
    }
  };

  const RichTextPullQuote = ({ attributionThumbnail, image, ...rest }) => {
    const serializedThumbnail = serialize(attributionThumbnail, [CURRENT_LANGUAGE, 'fields']);
    const serializedImage = serialize(image, [CURRENT_LANGUAGE, 'fields']);

    const {
      content,
      showQuotes,
      type,
      attribution,
      attributionSecondLine,
      attributionLink,
    } = safeGetForLanguage(
      rest,
      'content',
      'showQuotes',
      'type',
      'attribution',
      'attributionSecondLine',
      'attributionLink',
    );

    return (
      <PullQuote
        content={content}
        withQuotes={showQuotes}
        attribution={attribution}
        attributionSecondLine={attributionSecondLine}
        attributionLink={attributionLink}
        attributionThumbnail={serializedThumbnail}
        image={serializedImage}
        type={type}
      />
    );
  };

  const RichTextVideoPlayer = props => {
    const {
      url,
      coverImage: coverImageFields,
      contentfulVideo: contentfulVideoFields,
      caption,
      title,
      transcriptItems,
      showTranscript = props.showTranscript === undefined || props.showTranscript === null
        ? false
        : props.showTranscript['en-US'],
      duration,
    } = safeGetForLanguage(
      props,
      'url',
      'coverImage',
      'contentfulVideo',
      'caption',
      'title',
      'transcriptItems',
      'duration',
    );

    const cover =
      coverImageFields &&
      safeGetForLanguage(coverImageFields, ['fields', 'title'], ['fields', 'file']);
    const contentfulVideo = safeGetForLanguage(contentfulVideoFields, ['fields', 'file']);

    return (
      <VideoPlayer
        url={url}
        contentfulVideo={contentfulVideo}
        cover={cover}
        caption={caption}
        title={title}
        transcriptItems={transcriptItems}
        showTranscript={showTranscript}
        duration={duration}
      />
    );
  };

  const RichTextMediaLayout = data => {
    const { caption, media, ...rest } = data;
    const props = {
      ...serialize(rest),
      caption: get(caption, CURRENT_LANGUAGE),
      media: get(media, CURRENT_LANGUAGE, []).map(asset => serialize(asset)),
    };

    return <MediaLayout {...props} />;
  };

  const RichTextCTA = data => {
    const ctaProps = serialize(data);
    return <CTA {...ctaProps} />;
  };

  const RichTextBookmark = data => {
    const props = serialize(data);
    return <Bookmark target="_self" {...props} />;
  };

  const RichTextButtonLink = data => {
    const props = serialize(data);
    return <ButtonLink {...props} />;
  };

  const RichTextCarousel = data => {
    const props = serialize(data);
    return <Carousel {...props} />;
  };

  const RichTextStickyNavigation = data => {
    const props = serialize(data);
    const { menus } = safeGetForLanguage(data, ['menus']);
    if (menus) {
      const refinedMenus = menus.map(menu => {
        if (menu.fields.heading) {
          return safeGetForLanguage(menu.fields, ['heading'], ['headingUrl'], ['list']);
        }
        return safeGetForLanguage(menu.fields, ['buttonLabel'], ['buttonLink']);
      });

      return <StickyNavigation {...props} menus={refinedMenus} />;
    } else {
      return <></>;
    }
  };

  const RichTextSidebarStickyMenu = data => {
    const props = serialize(data);
    return <SidebarStickyMenu {...props} />;
  };

  const RichTextInlineIcon = data => {
    const props = serialize(data);
    if (props.type === 'Font Awesome Icon') {
      return <InlineFontawesomeIcon html={props.fontawesomeClass} />;
    }

    const { name } = safeGetForLanguage(data, ['name']);
    const serializedImage = serialize(data.icon, [CURRENT_LANGUAGE, 'fields']);
    return <InlineIcon name={name} icon={serializedImage} />;
  };

  const RichTextCeros = data => {
    const props = serialize(data);
    return <Ceros html={props.embedCode} />;
  };

  const RichTextSpacer = data => <Spacer {...serialize(data)} />;

  const RichTextColumnCTA = data => {
    const props = serialize(data);
    return <ColumnCTA {...props} />;
  };

  const RichTextIconCTA = data => {
    const props = serialize(data);
    return <IconCTA {...props} />;
  };

  const RichTextTabs = data => {
    const { content1, content2, content3, content4, content5, ...rest } = data;
    const props = {
      ...serialize(rest),
      content1: get(content1, CURRENT_LANGUAGE),
      content2: get(content2, CURRENT_LANGUAGE),
      content3: get(content3, CURRENT_LANGUAGE),
      content4: get(content4, CURRENT_LANGUAGE),
      content5: get(content5, CURRENT_LANGUAGE),
    };
    return <Tabs {...props} />;
  };

  const RichTextSidebarImageCTA = data => {
    const props = serialize(data);
    return <SidebarImageCTA {...props} />;
  };

  const MenuTextLink = data => {
    console.log('Link Text Component Rendering:', data);
    const props = serialize(data);
    return <LinkText {...props} />;
  };

  const heroBanner = data => {
    console.log('Hero Banner Component Rendering:', data);
    const props = serialize(data);
    return <HeroBanner {...props} />;
  };

  const videoCarousel = data => {
    console.log('Video Carousel Component Rendering:', data);
    const props = serialize(data);
    return <VideoCarousel {...props} />;
  };

  const mapping = {
    [EMBEDDED_ENTRY_TYPES.BIG_LIST]: RichTextBigList,
    [EMBEDDED_ENTRY_TYPES.BOOKMARK]: RichTextBookmark,
    [EMBEDDED_ENTRY_TYPES.BUTTON_LINK]: RichTextButtonLink,
    [EMBEDDED_ENTRY_TYPES.FORM]: RichTextForm,
    [EMBEDDED_ENTRY_TYPES.MEDIA_LAYOUT]: RichTextMediaLayout,
    [EMBEDDED_ENTRY_TYPES.PULL_QUOTE]: RichTextPullQuote,
    [EMBEDDED_ENTRY_TYPES.RECIRCLIST]: RichTextRecircList,
    [EMBEDDED_ENTRY_TYPES.CTA]: RichTextCTA,
    [EMBEDDED_ENTRY_TYPES.CAROUSEL]: RichTextCarousel,
    [EMBEDDED_ENTRY_TYPES.CUSTOM_RICHTEXT]: RichTextCustom,
    [EMBEDDED_ENTRY_TYPES.EXPAND_COLLAPSE]: RichTextExpandCollapse,
    [EMBEDDED_ENTRY_TYPES.STICKY_NAVIGATION]: RichTextStickyNavigation,
    [EMBEDDED_ENTRY_TYPES.VIDEO]: RichTextVideoPlayer,
    [EMBEDDED_ENTRY_TYPES.INLINE_ICON]: RichTextInlineIcon,
    [EMBEDDED_ENTRY_TYPES.SPACER]: RichTextSpacer,
    [EMBEDDED_ENTRY_TYPES.COLUMN_CTA]: RichTextColumnCTA,
    [EMBEDDED_ENTRY_TYPES.ICON_CTA]: RichTextIconCTA,
    [EMBEDDED_ENTRY_TYPES.TABS]: RichTextTabs,
    [EMBEDDED_ENTRY_TYPES.SIDEBAR_IMAGE_CTA]: RichTextSidebarImageCTA,
    [EMBEDDED_ENTRY_TYPES.OPEN_LINK_LIST]: RichOpenLinkList,
    [EMBEDDED_ENTRY_TYPES.GRID]: RichTextGrid,
    [EMBEDDED_ENTRY_TYPES.SIDEBAR_STICKY_MENU]: RichTextSidebarStickyMenu,
    [EMBEDDED_ENTRY_TYPES.TIMELINE]: RichTextTimeline,
    [EMBEDDED_ENTRY_TYPES.CUSTOM_ARTICLE_COLUMNS]: RichTextCustomArticleColumnCAT,
    [EMBEDDED_ENTRY_TYPES.CEROS_EXPERIENCE]: RichTextCeros,
    [EMBEDDED_ENTRY_TYPES.LINK_TEXT]: MenuTextLink,
    [EMBEDDED_ENTRY_TYPES.HERO_BANNER]: heroBanner,
    [EMBEDDED_ENTRY_TYPES.VIDEO_CAROUSEL]: videoCarousel,
  };

  const _component = mapping[contentType];
  return _component ? <_component {...fields} /> : null;
};

const renderText = (document, options = {}) =>
  documentToReactComponents(document.json, {
    renderMark: {
      ['subscript']: renderAdditionalFields(text => <sub>{text}</sub>),
      ['superscript']: renderAdditionalFields(text => <sup>{text}</sup>),
    },
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink((to, text) => (
        <Link to={to} partiallyActive activeClassName="navigation-current-page">
          {text}
        </Link>
      )),
      [INLINES.HYPERLINK]: renderHyperlink((to, text) =>
        /^#/.test(to) ? (
          <Link to={to} partiallyActive activeClassName="navigation-current-page">
            {text}
          </Link>
        ) : (
          <Link to={to} partiallyActive activeClassName="navigation-current-page" newTab>
            {text}
          </Link>
        ),
      ),
      [INLINES.ASSET_HYPERLINK]: renderAsset((_mimeGroup, url, title, description, text) => (
        <Link to={url} newTab>
          {text}
        </Link>
      )),
      [INLINES.EMBEDDED_ENTRY]: renderEmbeddedEntry,
      [BLOCKS.HEADING_6]: renderOutlinedText,
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
      [BLOCKS.EMBEDDED_ASSET]: renderAsset(
        (mimeGroup, url, title, description) =>
          mimeGroup === 'image' && (
            <figure>
              <img src={url} alt={description || title} />
            </figure>
          ),
      ),
      ...(options.renderNode || {}),
    },
  });

export default renderText;
