import React, { useEffect, useState } from 'react';
import { className, renderText, safeGet } from 'utils';
import PropTypes from 'prop-types';
import styles from './tabs.module.scss';
import { useIsMobile } from 'hooks';

const Tabs = ({ type, ...props }) => {
  const isMobile = useIsMobile();
  const iconType = type === 'Style 1';
  const tabsData = [1, 2, 3, 4, 5]
    .map(index => ({
      icon: props[`icon${index}`],
      headline: props[`headline${index}`],
      content: props[`content${index}`],
    }))
    .filter(item => item.headline && item.content && (!iconType || item.icon));

  const [activeTab, setActiveTab] = useState(tabsData[0]);

  // eslint-disable-next-line arrow-body-style
  const tabRender = () => {
    return tabsData.map(tab => {
      const { url: imageURL, description: imageDescription, title: imageTitle } = safeGet(
        tab,
        ['icon', 'file', 'url'],
        ['icon', 'title'],
        ['icon', 'description'],
      );
      if (isMobile) {
        return (
          <option
            key={String(tab.headline)}
            value={String(tab.headline)}
            {...className(
              activeTab.headline === tab.headline && styles.activeTab,
              styles.dropDownOption,
            )}>
            {String(tab.headline)}
          </option>
        );
      } else {
        return (
          <button
            key={tab.headline}
            className={activeTab.headline === tab.headline && styles.activeTab}
            onClick={() => setActiveTab(tab)}>
            {iconType && (
              <img className={styles.icon} src={imageURL} alt={imageDescription || imageTitle} />
            )}
            <p>{tab.headline}</p>
          </button>
        );
      }
    });
  };

  if (!activeTab) {
    return null;
  }

  return (
    <div {...className(styles.tabs)}>
      {isMobile ? (
        <select
          className={styles.dropDownContainer}
          value={String(activeTab.headline)}
          onChange={e => {
            const selectedTab = tabsData.find(tab => String(tab.headline) === e.target.value);
            if (selectedTab) {
              setActiveTab(selectedTab);
            }
          }}>
          {tabRender()}
        </select>
      ) : (
        <div {...className(styles.tabBar, iconType ? styles.iconType : '')}>{tabRender()}</div>
      )}
      <div className={styles.tabContainer}>{renderText({ json: activeTab.content })}</div>
    </div>
  );
};

Tabs.propTypes = {
  type: PropTypes.string,
};

export default Tabs;
